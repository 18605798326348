var chipApp;

chipApp = angular.module('ChipApp', ['vcRecaptcha']);

chipApp.controller('NavigationController', [
  function() {
    var _ncThis;
    this.visible = false;
    console.log(this.visible);
    _ncThis = this;
    this.showMenu = function() {
      _ncThis.visible = true;
    };
    this.hideMenu = function() {
      _ncThis.visible = false;
    };
  }
]);

chipApp.controller('QuoteController', [
  '$interval', function($interval) {
    var _qcThis;
    this.key = 0;
    this.quotes = [];
    this.quotes[0] = {
      quote: 'I called Jerry on Saturday morning. He was at my house within an hour. I thought I was going to pay him (which I can hardly afford) but he explained that my insurance would pay for it entirely. He is a very nice guy and did a great job on the rock chip! I would recommend him to my mother.',
      person: 'Gina J. (Belgrade, MT)'
    };
    this.quotes[1] = {
      quote: 'Jerry is a good guy even though he is a Packer fan. He went out of his way to meet my schedule. Highly Recommended!',
      person: 'Stan (Ennis, MT)'
    };
    this.quotes[2] = {
      quote: 'Great service! Great pricing! Jerry is doing what he said he would be doing. Taking care of our business fleet.',
      person: 'S.M. Business Owner (Bozeman, MT)'
    };
    _qcThis = this;
    $interval((function() {
      _qcThis.key += 1;
      if (_qcThis.key === _qcThis.quotes.length) {
        _qcThis.key = 0;
      }
    }), 5000);
  }
]);

chipApp.controller('ContactForm', [
  '$http', '$timeout', 'vcRecaptchaService', function($http, $timeout, vcRecaptchaService) {
    var _cfThis;
    this.key = '6LcUeAkTAAAAAO_8GW4prp9d_7W2qOn28oCkgxWW';
    this.sending = false;
    this.showSuccess = false;
    this.name = '';
    this.phone = '';
    this.message = '';
    this.errors = {
      name: '',
      phone: '',
      message: '',
      recaptcha: ''
    };
    _cfThis = this;
    this.send = function() {
      _cfThis.errors = {
        name: '',
        phone: '',
        message: '',
        recaptcha: ''
      };
      this.sending = true;
      $http.post('http://www.chipchipaway.com/contact.php', {
        name: this.name,
        phone: this.phone,
        message: this.message,
        recaptcha: vcRecaptchaService.getResponse()
      }).success(function(data) {
        var i;
        if (data.status === 'OK') {
          _cfThis.showSuccess = true;
          _cfThis.name = '';
          _cfThis.phone = '';
          _cfThis.message = '';
          $timeout((function() {
            _cfThis.showSuccess = false;
          }), 5000);
        } else if (data.status === 'VALIDATION_ERROR') {
          for (i in data.response) {
            _cfThis.errors[data.response[i].field] = data.response[i].message;
          }
        }
        _cfThis.sending = false;
      }).error(function(data) {
        console.log('Error: ' + data);
        _cfThis.sending = false;
      });
    };
  }
]);
